<template>
  <div id="yearwise-efficiency-edit">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
          </div>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                elevation="30"
                shaped
                tile
                small
                class="font-size-h6 mr-3 white--text"
                @click.prevent="refreshPageData"
                color="#a4c639"
              >Refresh</v-btn>
              <br />
            </v-col>
          </v-row>
          <v-form ref="form1" v-model="valid1" lazy-validation v-on:submit.prevent="submitForm">
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required
                    field
                  </p>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Year
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="JciYearCodeOptions"
                    :rules="JciYearCodeRules"
                    :loading="JciYearCodeOptionsLoading"
                    v-model="JciYearCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Criteria
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="CriteriaCodeOptions"
                    :rules="CriteriaCodeRules"
                    :loading="CriteriaCodeOptionsLoading"
                    v-model="CriteriaCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Points Type
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="PointsTypeOptions"
                    :rules="PointsTypeRules"
                    :loading="PointsTypeOptionsLoading"
                    v-model="PointsType"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Efficiency Points
                    </h6>
                  </label>
                  <v-text-field
                    v-model="EfficiencyPoints"
                    :rules="EfficiencyPointsRules"
                    placeholder="Efficiency Points"
                    required
                    outlined
                    dense
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>Efficiency Start Date</h6>
                  </label>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="EfficiencyStartDate"
                        placeholder="Efficiency Start Date"
                        readonly
                        v-on="on"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="EfficiencyStartDate" @input="menu1 = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>Efficiency End Date</h6>
                  </label>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="EfficiencyEndDate"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        placeholder="Efficiency End Date"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="EfficiencyEndDate" @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>Efficiency Status</h6>
                  </label>
                  <v-switch
                    inset
                    v-model="EfficiencyStatus"
                    :label="`${
                            EfficiencyStatus
                              ? 'Active'
                              : 'Inactive'
                          }`"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                  >Submit</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    EditRecordPrompt: {
      type: Boolean,
      required: true
    },
    recordData: {
      required: true
    }
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,

      menu1: false,
      menu2: false,

      JciYearCodeRules: [v => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      PointsTypeRules: [v => !!v || "Points Type is required"],
      PointsType: "",
      PointsTypeOptions: [],
      PointsTypeOptionsLoading: false,

      CriteriaCodeRules: [v => !!v || " Criteria is required"],
      CriteriaCode: "",
      CriteriaCodeOptions: [],
      CriteriaCodeOptionsLoading: false,

      YearwiseEfficiencyCode: "",
      EfficiencyPoints: 0,
      EfficiencyStatus: 1,
      EfficiencyStartDate: "",
      EfficiencyEndDate: "",

      EfficiencyPointsRules: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  computed: {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;

        this.YearwiseEfficiencyCode = this.recordData.YearwiseEfficiencyId;
        this.JciYearCode = this.recordData.YearCode;
        this.PointsType = this.recordData.PointsType;
        this.CriteriaCode = this.recordData.CriteriaCode;
        this.EfficiencyPoints = this.recordData.EfficiencyPoints;
        this.EfficiencyStartDate = this.recordData.EfficiencyStartDate;
        this.EfficiencyEndDate = this.recordData.EfficiencyEndDate;
        this.EfficiencyStatus = this.recordData.ActiveStatus;

        this.getJciYearCodeOptions();
        this.getPointsTypeOptions();
        this.getCriteriaCodeOptions();
      }
    },
    JciYearCodeOptions: function() {
      console.log("watch JciYearCodeOptions");
      this.JciYearCodeOptionsLoading = false;
    },
    PointsTypeOptions: function() {
      console.log("watch PointsTypeOptions");
      this.PointsTypeOptionsLoading = false;
    },
    CriteriaCodeOptions: function() {
      console.log("watch CriteriaCodeOptions");
      this.CriteriaCodeOptionsLoading = false;
    }
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "full_efficiency",
        Action: "edit"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      this.JciYearCodeOptionsLoading = true;
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 3
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getPointsTypeOptions() {
      console.log("getPointsTypeOptions called");
      this.PointsTypeOptionsLoading = true;
      var selectbox1_source = "PointsType";
      var selectbox1_destination = "PointsTypeOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
        CategoryMainName: "efficiency_points_type"
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getCriteriaCodeOptions() {
      console.log("getCriteriaCodeOptions called");
      this.CriteriaCodeOptionsLoading = true;
      var selectbox1_source = "CriteriaCode";
      var selectbox1_destination = "CriteriaCodeOptions";
      var selectbox1_url = "api/efficiency-sub-criteria/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
        Where: [["efficiency_sub_criteria_active_status", "=", 1]],
        OrderBy: "efficiency_sub_criteria_name ASC"
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var upload = {
          UserInterface: 1,
          YearwiseEfficiency: this.YearwiseEfficiencyCode,
          Year: this.JciYearCode,
          PointsType: this.PointsType,
          Criteria: this.CriteriaCode,
          EfficiencyPoints: this.EfficiencyPoints,
          EfficiencyStartDate: this.EfficiencyStartDate,
          EfficiencyEndDate: this.EfficiencyEndDate,
          EfficiencyStatus: this.EfficiencyStatus
        };
        console.log({ upload });

        this.progessStart = 1;
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/yearwise-efficiency/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
#yearwise-efficiency-edit {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>